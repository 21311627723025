import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { projectContent } from './Data/ProjectData';

const ParticleEffect = ({ cursorPosition }) => {
  const [particles, setParticles] = useState([]);
  const colors = ['#008080', '#00BFFF', '#20B2AA', '#5F9EA0', '#3CB371'];

  useEffect(() => {
    const newParticle = {
      id: Date.now(),
      x: cursorPosition.x,
      y: cursorPosition.y,
      xVelocity: (Math.random() - 0.5) * 5,
      yVelocity: (Math.random() - 0.5) * 5,
      color: colors[Math.floor(Math.random() * colors.length)],
    };
    setParticles((prev) => [...prev, newParticle]);

    const timeout = setTimeout(() => {
      setParticles((prev) => prev.filter((p) => p.id !== newParticle.id));
    }, 2000);

    return () => clearTimeout(timeout);
  }, [cursorPosition]);

  useEffect(() => {
    const moveParticles = () => {
      setParticles((prev) =>
        prev.map((p) => ({
          ...p,
          x: p.x + p.xVelocity,
          y: p.y + p.yVelocity,
        }))
      );
    };

    const animationInterval = setInterval(moveParticles, 30);
    return () => clearInterval(animationInterval);
  }, []);

  return (
    <>
      {particles.map((p) => (
        <div
          key={p.id}
          className="absolute"
          style={{
            width: '5px',
            height: '5px',
            left: p.x,
            top: p.y,
            backgroundColor: p.color,
            pointerEvents: 'none',
            borderRadius: '50%',
          }}
        />
      ))}
    </>
  );
};

const ProjectSection = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const projectsPerPage = 10;

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedMode);
    setLoaded(true);
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleDarkMode = () => setDarkMode((prev) => !prev);
  const toggleLanguage = () => setLanguage((prev) => (prev === 'EN' ? 'ID' : 'EN'));

  const filteredProjects = projectContent.filter(
    (project) =>
      (selectedTag === 'All' || project.tag === selectedTag) &&
      project.name &&
      project.name[language] &&
      project.name[language].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  return (
    <div
      className={`min-h-screen flex flex-col items-center ${
        darkMode ? 'bg-black' : 'bg-gradient-to-r from-gray 050 via-gray-50 to-gray-050'
      } dark:text-white relative overflow-hidden`}
      style={{ width: '100%', height: '100vh' }} // Ensure full screen coverage
      onMouseMove={(e) => setCursorPosition({ x: e.clientX, y: e.clientY })}
    >
      <ParticleEffect cursorPosition={cursorPosition} />

      {/* Language and Theme Toggle */}
      <div className="absolute z-10 flex items-center space-x-4 top-4 right-4">
        <button onClick={toggleLanguage} className="focus:outline-none">
          <span className="text-lg font-bold">{language === 'EN' ? 'ID' : 'EN'}</span>
        </button>
      </div>

      {/* Header */}
      <div className="text-center mt-14">
        <h3 className="mb-5 text-xl font-semibold text-green-600 transition-all duration-500">
          {language === 'EN' ? 'Projects' : 'Proyek'}
        </h3>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 w-full max-w-6xl px-4 pb-20 mx-auto overflow-y-auto">
        <div className="relative mb-4">
          <input
            type="text"
            placeholder={language === 'EN' ? 'Search projects...' : 'Cari proyek...'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border border-gray-100 rounded dark:bg-opacity-30 dark:bg-gray-20 dark:border-gray-800 dark:text-white focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded dark:bg-opacity-30 dark:bg-gray-100 dark:border-gray-600 dark:text-gray-800 focus:outline-none focus:border-blue-500"
          >
            {['All', 'Thesis', 'Software Engineer', 'Website Developer', 'Mobile Developer', 'Artificial Intelligence', 'Game Developer', 'UI/UX Design'].map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
        </div>

        {/* Project List */}
        <div className="grid grid-cols-1 gap-4 mt-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {currentProjects.map((project, index) => (
            <Link
              key={index}
              to={`/project/${project.id}`}
              className="flex flex-col m-2 overflow-hidden transition-transform duration-500 bg-white rounded-lg shadow-lg cursor-pointer dark:bg-gray-800 hover:scale-105"
            >
              <img
                src={project.image}
                alt={project.name && project.name[language]}
                className="object-cover w-full h-48 transition-transform duration-500 transform hover:scale-110"
              />
              <div className="flex flex-col justify-between flex-1 p-6 ">
                <h3 className="mb-4 text-lg font-semibold transition-all duration-500">
                  {project.name && project.name[language]}
                </h3>

                 <span className={`inline-block px-3 py-1 text-xs font-semibold rounded-full ${darkMode ? 'bg-teal-800 text-teal-050' : 'bg-teal-100 text-teal-600'} whitespace-nowrap`}>
                    {project.stack}
                  </span>
                  
                <div className="flex items-center justify-between mt-4">
                  <span className={`inline-block px-3 py-1 text-xs font-semibold rounded-full ${darkMode ? 'bg-teal-800 text-teal-100' : 'bg-teal-200 text-teal-600'} whitespace-nowrap`}>
                    {project.tag}
                  </span>
                  
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-between mt-8">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="flex items-center px-4 py-2 text-sm font-semibold text-white bg-teal-500 rounded-full hover:bg-teal-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5 mr-2" />
            {language === 'EN' ? 'Previous' : 'Sebelumnya'}
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="flex items-center px-4 py-2 text-sm font-semibold text-white bg-teal-500 rounded-full hover:bg-teal-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {language === 'EN' ? 'Next' : 'Selanjutnya'}
            <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
