// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes
import Header from './components/Header';
import ProfileSection from './components/ProfileSection';
import ProjectSection from './components/ProjectSection';
import BottomNavBar from './components/BottomNavBar';
import ProjectPost from './components/Project/ProjectPost';

function App() {
  return (
    <Router>
      <div>
        <main className="px-4">
          <Routes>
            <Route path="/" element={<Header />} /> {/* Home route */}
            <Route path="/project" element={<ProjectSection/>} />
            <Route path="/profile" element={<ProfileSection/>}/>
            <Route exact path="/project/:projectId" element={<ProjectPost />} />
            {/* Add more routes as needed */}
          </Routes>
        </main>
        <BottomNavBar />
      </div>
    </Router>
  );
}

export default App;
