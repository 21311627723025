import React from 'react';
import { HomeIcon, FolderIcon, UserIcon, SunIcon, MoonIcon } from '@heroicons/react/24/solid'; // Ensure correct imports

const BottomNavBar = ({ darkMode, toggleDarkMode }) => {
  return (
    // Floating Bottom Navbar
    <nav
      className={`fixed bottom-6 left-1/2 transform -translate-x-1/2 py-3 px-6 rounded-full shadow-lg flex justify-around w-72 items-center backdrop-filter backdrop-blur-md bg-opacity-50 ${
        darkMode ? 'bg-teal-700' : 'bg-white'
      } transition-colors duration-300`}
    >
      {[  
        { icon: <HomeIcon className="w-6 h-6" />, onClick: () => (window.location.href = '/') },
        { icon: <FolderIcon className="w-6 h-6" />, onClick: () => (window.location.href = '/Project') },
        { icon: <UserIcon className="w-6 h-6" />, onClick: () => (window.location.href = '/Profile') },
      ].map((item, index) => (
        <button
          key={index}
          onClick={item.onClick}
          className="transition-transform duration-300 transform focus:outline-none hover:scale-125"
        >
          <span className={`text-gray-700 dark:text-gray-300 hover:text-teal-500 dark:hover:text-teal-400`}>
            {item.icon}
          </span>
        </button>
      ))}
    </nav>
  );
};

export default BottomNavBar;
