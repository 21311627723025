import React, { useState, useEffect } from 'react';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { profileContent } from './Data/profileData'; // Ensure this path is correct

const ProfileSection = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [dropdown, setDropdown] = useState('all');
  const [isVisibleImage, setIsVisibleImage] = useState(false);
  const [isVisibleTitle, setIsVisibleTitle] = useState(false);
  const [isVisibleBio, setIsVisibleBio] = useState(false);
  const [isVisibleCards, setIsVisibleCards] = useState(false);
  const [isVisibleButtons, setIsVisibleButtons] = useState(false);


  const [hoveredTech, setHoveredTech] = useState(null);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(JSON.parse(savedMode));
    }
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }

    setTimeout(() => {
      setIsVisibleImage(true);
    }, 100); // Delay for image

    setTimeout(() => {
      setIsVisibleTitle(true);
    }, 300); // Delay for title

    setTimeout(() => {
      setIsVisibleBio(true);
    }, 500); // Delay for bio

    setTimeout(() => {
      setIsVisibleCards(true);
    }, 700); // Delay for cards

    setTimeout(() => {
      setIsVisibleButtons(true);
    }, 900); // Delay for buttons
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'EN' ? 'ID' : 'EN'));
  };

  const content = profileContent[language] || {};

  return (
    <div
      className={`min-h-screen flex flex-col items-center ${
        darkMode ? 'bg-black' : 'bg-gradient-to-r from-gray-050 via-gray-50 to-gray-050'
      } dark:text-white relative overflow-hidden`}
    >
      <div className="absolute z-50 flex items-center space-x-4 top-4 right-4">
        <button onClick={toggleLanguage} className="focus:outline-none">
          <span className="text-lg font-bold">{language === 'EN' ? 'ID' : 'EN'}</span>
        </button>
      </div>
      <div
        className={`flex flex-col items-center mb-8 space-y-4 mt-14 transition-all duration-500 ${
          isVisibleImage ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
        }`}
      >
        <img
          src="/assets/myprofile.jpg"
          alt="Profile"
          className="object-cover w-32 h-32 rounded-full"
        />
      </div>
      <div
        className={`text-center transition-all duration-500 ${
          isVisibleTitle ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
        }`}
      >
        <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-800">{content.title}</h1>
        <p className="text-lg text-gray-600 dark:text-gray-600">{content.location}</p>
      </div>
      <div className="flex space-x-4 transition-all duration-500">
        <a
          href="https://github.com/gigarazkiarianda"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
        >
          <FaGithub className="w-6 h-6" />
        </a>
        <a
          href="https://instagram.com/gigarazkiarianda"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
        >
          <FaInstagram className="w-6 h-6" />
        </a>
        <a
          href="https://www.linkedin.com/in/gigarazkiarianda/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
        >
          <FaLinkedin className="w-6 h-6" />
        </a>
      </div>
      <div
        className={`w-full max-w-5xl px-4 mx-auto mt-4 text-center transition-all duration-500 ${
          isVisibleBio ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
        }`}
      >
        <p className="mb-5 text-sm leading-relaxed text-justify text-gray-700 dark:text-gray-800 md:text-base">
          {content.bio}
        </p>
      </div>
      <div className="relative flex flex-col flex-1 w-full max-w-5xl px-4 mx-auto mb-24">
      <div className="flex justify-start mb-8 space-x-4 overflow-x-auto">
  {['all', 'education', 'experience', 'certificate', 'techStack'].map((item, index) => (
    <button
      key={item}
      onClick={() => setDropdown(item)}
      className={`w-32 min-w-[150px] px-4 py-2 text-center rounded-md transition-all duration-500 transform ${
        dropdown === item
          ? 'bg-teal-500 text-white scale-105'
          : 'bg-gray-200 dark:bg-gray-800'
      } ${
        isVisibleButtons ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
      }`}
      style={{ transitionDelay: `${index * 100}ms` }} // Delay for each button
    >
      {content[item] || item.charAt(0).toUpperCase() + item.slice(1)}
    </button>
  ))}
</div>
        <div className="space-y-4">
          {['all', 'education'].includes(dropdown) && content.schools && (
            <div className="space-y-4">
              <h1 className={`transition-all duration-500 ${isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
                {content.education}
              </h1>
              {content.schools.map((school, index) => (
                <div
                  key={index}
                  className={`p-4 transition-transform transform bg-teal-100 rounded-md shadow-lg dark:bg-gray-800 hover:scale-105 ${
                    isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
                  }`}
                  style={{ transitionDelay: `${index * 100}ms` }} // Delay for each card
                >
                  <h3 className="text-xl font-semibold">{school.name}</h3>
                  <p className="text-gray-600 dark:text-gray-400">{school.location}</p>
                  <p className="text-gray-800 dark:text-gray-300">{school.degree}</p>
                  <p className="text-gray-600 dark:text-gray-400">{school.years}</p>
                </div>
              ))}
            </div>
          )}
          {['all', 'experience'].includes(dropdown) && content.jobs && (
            <div className="space-y-4">
              <h1 className={`transition-all duration-500 ${isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
                {content.experience}
              </h1>
              {content.jobs.map((job, index) => (
                <div
                  key={index}
                  className={`p-4 transition-transform transform bg-teal-100 rounded-md shadow-lg dark:bg-gray-800 hover:scale-105 ${
                    isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
                  }`}
                  style={{ transitionDelay: `${index * 100}ms` }} // Delay for each card
                >
                  <h3 className="text-xl font-semibold">{job.title}</h3>
                  <p className="text-gray-600 dark:text-gray-400">{job.company}</p>
                  <p className="text-gray-800 dark:text-gray-300">{job.location}</p>
                  <p className="text-gray-600 dark:text-gray-400">{job.years}</p>
                  <p className="text-gray-800 dark:text-gray-300">{job.jobtype}</p>
                  <p className="text-gray-600 dark:text-gray-400">{job.jobdesc}</p>
                </div>
              ))}
            </div>
          )}
          {['all', 'certificate'].includes(dropdown) && content.certificates && (
            <div className="space-y-4">
            <h1 className={`transition-all duration-500 ${isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>{content.certificate}</h1>
              {content.certificates.map((certificate, index) => (
                <div
                key={index}
                className={`p-4 transition-transform transform bg-teal-100 rounded-md shadow-lg dark:bg-gray-800 hover:scale-105 ${
                  isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
                }`}
                style={{ transitionDelay: `${index * 100}ms` }} // Delay for each card
                >
                  <h3 className="text-xl font-semibold">{certificate.name}</h3>
                  <p className="text-gray-600 dark:text-gray-400">{certificate.issuer}</p>
                  <p className="text-gray-800 dark:text-gray-300">{certificate.year}</p>
                  <p>
                    <a className="text-gray-800 dark:text-gray-300" href={certificate.Link}>Show Credential</a>
                  </p>
                </div>
              ))}
            </div>
          )}
          {['all', 'techStack'].includes(dropdown) && content.techStackDetails && (
            <div className="space-y-4">
              <h1 className={`transition-all duration-500 ${isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>{content.techStack}</h1>
              {Object.keys(content.techStackDetails).map((category, index) => (
                <div
                  key={index}
                  className={`p-4 transition-transform transform bg-teal-100 rounded-md shadow-lg dark:bg-gray-800 hover:scale-105 ${isVisibleCards ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
                  style={{ transitionDelay: `${index * 100}ms` }}
                >
                  <h3 className="mt-4 mb-4 text-xl font-semibold text-center">
                    {category.replace(/([A-Z])/g, ' $1').trim()}
                  </h3>
                  <ul className="flex flex-wrap justify-center">
                    {content.techStackDetails[category].map((tech, idx) => {
                      return (
                        <li key={idx} className="flex items-center w-full p-2 md:w-1/2 lg:w-1/4">
                          <div
                            className="flex items-center justify-between w-full p-4 text-center transition-transform transform bg-teal-100 rounded-lg shadow-lg dark:bg-gray-800 hover:scale-105"
                            onMouseEnter={() => setHoveredTech(tech.name)}
                            onMouseLeave={() => setHoveredTech(null)}
                          >
                            <div className="flex items-center">
                              {tech.icon ? (
                                <tech.icon className="w-8 h-8 mx-auto mb-2" />
                              ) : (
                                <img
                                  src={tech.badge}
                                  alt={tech.name}
                                  className="max-w-[80px] max-h-[80px] w-auto h-auto mx-auto mb-2"
                                />
                              )}
                            </div>
                            <span className={`text-xs text-gray-600 dark:text-gray-400 transition-opacity duration-300 ${hoveredTech === tech.name ? 'opacity-100' : 'opacity-0'}`} style={{ marginLeft: '8px' }}>
                              {tech.level}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
