import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  GlobeAltIcon,
  UserGroupIcon,
  ArchiveBoxIcon,
  LinkIcon, // Importing LinkIcon for the link button
} from '@heroicons/react/24/outline';
import { projectContent } from '../Data/ProjectData';

const ProjectPost = () => {
  const { projectId } = useParams();
  const project = projectContent.find((project) => project.id === projectId);

  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState('EN');

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    setDarkMode(savedMode === 'true');
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'EN' ? 'ID' : 'EN');
  };

  if (!project) {
    return <div>Project not found!</div>;
  }

  const projectName = project.name?.[language] || 'Project Name Unavailable';
  const projectDescription = project.description?.[language] || 'Description Unavailable';
  const projectTag = project.tag || 'Tag Unavailable';
  const projectClient = project.client || 'Client Unavailable';
  const projectLanguages = project.languages?.join(', ') || 'Languages Unavailable';
  const projectLink = project.link || 'Link Unavailable';

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      <div className="absolute flex items-center space-x-4 top-4 right-4">
        <button 
          onClick={toggleLanguage} 
          className="px-4 py-2 font-bold text-white transition bg-green-500 rounded focus:outline-none hover:bg-green-600"
        >
          {language === 'EN' ? 'ID' : 'EN'}
        </button>
      </div>
      <main className="container flex-grow p-4 mx-auto mt-12 mb-14">
        <h1 className="mb-4 text-2xl font-semibold text-center text-green-600">{projectName}</h1>
        <p className="flex items-center justify-center mb-4">
          <span className="flex items-center">
            <GlobeAltIcon className="w-4 h-4 mr-1 text-green-500" />
            {projectLanguages}
          </span>
        </p>
        <img 
          src={project.image} 
          alt={projectName} 
          className="object-cover w-full mx-auto mt-10 mb-4 rounded-lg md:w-auto md:max-h-80" 
        />
        <p className="mb-4 ml-5 mr-5" dangerouslySetInnerHTML={{ __html: projectDescription }}></p>
        <p className="mb-4 ml-5">
          <span className="flex items-center">
            <ArchiveBoxIcon className="w-4 h-4 mr-1 text-green-500" />
            {projectTag}
          </span>
        </p>
        <p className="mb-4 ml-5">
          <span className="flex items-center">
            <UserGroupIcon className="w-4 h-4 mr-1 text-green-500" />
            {projectClient}
          </span>
        </p>
        <div className="mt-2 mb-4 ml-4 text-left">
          <a 
            href={projectLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center justify-center px-2 py-1 text-sm font-bold text-white transition bg-green-500 rounded hover:bg-green-600"
          >
            <LinkIcon className="w-4 h-4 mr-1" /> 
            View Project
          </a>
        </div>
      </main>
    </div>
  );
};

export default ProjectPost;
