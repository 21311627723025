import React, { useState, useEffect } from 'react';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import BottomNavBar from './BottomNavBar'; // Import your BottomNavBar
import '../App.css';

const Header = () => {
  const [name, setName] = useState('Giga Razki Arianda');
  const [profession, setProfession] = useState('Software Engineer');
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [loaded, setLoaded] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });


  useEffect(() => {
    // Disable scrolling on mount
    const preventScroll = () => {
      document.body.style.overflow = 'hidden';
    };
    
    // Enable scrolling on unmount
    const enableScroll = () => {
      document.body.style.overflow = 'unset';
    };
    
    preventScroll();
    
    return () => enableScroll(); // Re-enable scrolling when component unmounts
  }, []);
  
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      setDarkMode(JSON.parse(savedMode));
    }
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const toggleDarkMode = () => {
    setDarkMode((prev) => !prev);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'EN' ? 'ID' : 'EN');
  };

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
    className={`h-screen flex flex-col items-center ${darkMode ? 'bg-black' : 'bg-gradient-to-r from-gray-050 via-gray-50 to-gray-050'} dark:text-white relative overflow-hidden`}
    style={{ 
      backgroundAttachment: 'fixed', 
      width: '100vw', 
      overflow: 'hidden', 
    }}
  >
      {/* Particle Effect */}
      <ParticleEffect cursorPosition={cursorPosition} />

      {/* Main Content */}
      <main
        className={`flex-grow flex flex-col-reverse sm:flex-row items-center justify-center mt-24 sm:mt-28 ${loaded ? 'fade-in' : ''}`}
      >
        {/* Left Section: Name and Profession */}
        <div className={`text-center sm:text-left sm:mr-12 ${loaded ? 'slide-in' : ''}`}>
          <h1 className="text-4xl font-bold sm:text-5xl">
            <span className="gradient-text-full animate-gradient">{name}</span>
          </h1>
          <div className="flex flex-col text-center sm:text-left sm:mr-12">
            <h3 className="mt-2 text-3xl font-semibold sm:text-4xl">
              <span className={`text-teal-500 ${darkMode ? 'gradient-text-dark' : 'gradient-text-light'}`}>
                {profession}
              </span>
            </h3>
          </div>
          {/* Social Links and Curriculum Vitae Button */}
          <div className="flex items-center mt-4 space-x-4">
            <a
              href="https://drive.google.com/file/d/1Q5YoUuAbQWM27dPS0cMrB5EocXdUCuDv/view?usp=sharing"
              download
              className={`inline-block px-6 py-2 text-black dark:text-white transition-all duration-300 bg-white dark:bg-teal-500 rounded-lg hover:bg-teal-500 dark:hover:bg-teal-400`}
            >
              {language === 'EN' ? 'Curriculum Vitae' : 'CV'}
            </a>
            <a
              href="https://github.com/gigarazkiarianda"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 dark:text-gray-300 hover:text-teal-500 dark:hover:text-teal-400"
            >
              <FaGithub className="w-6 h-6" />
            </a>
            <a
              href="https://instagram.com/gigarazkiarianda"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 dark:text-gray-300 hover:text-teal-500 dark:hover:text-teal-400"
            >
              <FaInstagram className="w-6 h-6" />
            </a>
            <a
              href="https://www.linkedin.com/in/gigarazkiarianda/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 dark:text-gray-300 hover:text-teal-500 dark:hover:text-teal-400"
            >
              <FaLinkedin className="w-6 h-6" />
            </a>
          </div>
        </div>

        {/* Right Section: Profile Picture */}
        <div className={`relative flex items-center justify-center ${loaded ? 'slide-in' : ''}`}>
          <div className="relative">
            <div className="absolute inset-0 z-0 bg-teal-400 border border-teal-500 rounded-lg bg-opacity-15 h-60 w-60 sm:w-60 sm:h-60 animate-box backdrop-blur-sm"></div>
            <img
              src="/assets/myprofile.jpg"
              alt="Profile"
              className={`z-10 object-cover w-60 h-60 border border-gray-300 rounded-lg shadow-lg transition-transform duration-1000 animate-rotate-once`}
            />
          </div>
        </div>
      </main>

      {/* Bottom Navigation Bar */}
      <BottomNavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

      <style jsx>{`
        .gradient-text-full {
          background: linear-gradient(to right, #008080, #ffffff);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          background-size: 300% 300%;
        }

        .animate-gradient {
          animation: gradientAnimation 5s ease infinite;
        }

        @keyframes gradientAnimation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .gradient-text-light {
          background: linear-gradient(to right, #008080, #66b3b3);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }

        .gradient-text-dark {
          background: linear-gradient(to right, #005757, #008080);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }

        .fade-in {
          animation: fadeIn 1.5s ease-in-out;
        }

        .slide-in {
          animation: slideIn 0.5s forwards;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes slideIn {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes moveBox {
          0% {
            transform: translateY(0) rotate(0deg);
          }
          100% {
            transform: translateY(20px) rotate(5deg);
          }
        }

        /* Animation for the profile picture to rotate once */
        .animate-rotate-once {
          animation: rotateOnce 1s forwards;
        }

        @keyframes rotateOnce {
          0% {
            transform: translateY(20px) rotate(0deg);
          }
          100% {
            transform: translateY(0) rotate(10deg); /* Slightly tilted to the right */
          }
        }
      `}</style>
    </div>
  );
};

const ParticleEffect = ({ cursorPosition }) => {
  const [particles, setParticles] = useState([]);
  const colors = ['#008080', '#00BFFF', '#20B2AA', '#5F9EA0', '#3CB371'];

  useEffect(() => {
    const newParticle = {
      id: Date.now(),
      x: cursorPosition.x,
      y: cursorPosition.y,
      xVelocity: (Math.random() - 0.5) * 5,
      yVelocity: (Math.random() - 0.5) * 5,
      color: colors[Math.floor(Math.random() * colors.length)],
    };
    setParticles((prev) => [...prev, newParticle]);

    const timeout = setTimeout(() => {
      setParticles((prev) => prev.filter((p) => p.id !== newParticle.id));
    }, 2000);

    return () => clearTimeout(timeout);
  }, [cursorPosition]);

  useEffect(() => {
    const moveParticles = () => {
      setParticles((prev) =>
        prev.map((p) => ({
          ...p,
          x: p.x + p.xVelocity,
          y: p.y + p.yVelocity,
        }))
      );
    };

    const animationInterval = setInterval(moveParticles, 30);
    return () => clearInterval(animationInterval);
  }, []);

  return (
    <>
      {particles.map((p) => (
        <div
          key={p.id}
          className="absolute"
          style={{
            width: '5px',
            height: '5px',
            left: p.x,
            top: p.y,
            backgroundColor: p.color,
            pointerEvents: 'none',
            borderRadius: '50%',
          }}
        />
      ))}
    </>
  );
};

export default Header;
